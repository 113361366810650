
.repo-name-div{
    display:flex;
    align-items: center;
}

.repo-name{
    white-space: nowrap;
    line-height: 1.1;
    text-overflow: ellipsis;
    color: rgb(36,41,46);
    font-family:"Google Sans Regular";
    margin-bottom: 0.75rem;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.5px;
    overflow: hidden;
    margin:0px;
}

.repo-status{
    white-space: nowrap;
    font-family: "Google Sans Regular";
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
    margin:0px;
}

.repo-description{
    margin:5px;
    font-family:"Google Sans Regular";
    font-weight: 500;
    margin-left: 0px;
    margin-right: auto;
    font-size: 18px;
}

@media(max-width:768px){
    .repo-name{
        font-size:16px;
    }
    .repo-status{
        font-size: 16px;
    }
    .repo-description{
        font-size: 14pxs;
    }
}