
.education-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.education-main {
  display:flex;
}

.education-image-div{
  margin:50px;
}

.education-image{
  max-width: 750px;
}

.education-text {
  padding-top: 50px;
  padding-right:50px;
}

.education-text > * {
  flex:1;
}
@media (max-width: 1380px){

}

@media (max-width: 768px){
  .education-text{
    padding: 50px;
  }
  .education-main{
    flex-direction: column;
  }

  .education-image-div{
    margin: 0px;
  }
  .education-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

}