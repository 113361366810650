.articleTitle{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 56px;
    font-weight: 600;
    padding-bottom: 10px;
}

.information{
    margin-left: 30%;
    margin-right: 30%;
}

.info{
    font-size: 10px;
    text-align: center;
}

@media (max-width: 768px){
    .articleTitle{
        font-size: 32px;
    }
}