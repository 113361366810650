.blog-summary-card {
    width: 90%;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    height:auto;
    margin-left: 5%;
    margin-right: 5%;
}

.blog-link{
  display: flex;
}

.blog-summary-image{
    width: 20%;
    float:left;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
}

.blog-summary-main{
    border-radius: 7px;
    width: 80%;
    padding-top: 20px;
    margin-right: auto;
    margin-left:auto;
    float:left;
    margin-top: 0px;
    transition: all 0.2s ease-in-out;
    font-size: 25px;
}

.body-header {
  max-width: inherit;
  display: flex;
  border-radius: 7px 7px 0px 0px;
  padding: 10px;
  padding-top: 0px;
}
  
.body-header-title {
  text-align: justify;
  align-items: flex-start;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.body-header-dates {
  width: 20%;
  padding-top: 10px;
}

.body-header-date {
  text-align: center;
  font-size: 12px;
}

.body-content {
  max-width: inherit;
  border-radius: 0px 0px 7px 7px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}


@media (max-width:768px) {
    .blog-summary-image{
        margin-left: 15%;
        margin-right: 15%;
        width:70%;
        order:2;
        flex-direction: row;
        display:flex;
    }

    .blog-summary-main{
        padding-top: 10px;
        width:100%;
        order:1;
        flex-direction: row;
    }

    .body-content{
      max-height: 300px;
      overflow: hidden;
      text-align: justify;
      width:90%;
      margin-left: auto;
      margin-right: auto;
    }

    .body-header-dates{
      display: none;
    }

    .body-header-title h2{
      font-size: 22px;
      text-align: center;
    }

    .blog-link{
      display:flex;
      flex-direction: column;
    }
    
    .blog-summary-card{
      display:flex;
    }
}

