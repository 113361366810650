
.social-media-div {
    font-size: 2em;
    display:flex;
}

.icon-button i {
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5em;
    height: 2.6rem;
    width: 2.6rem;
    line-height: 2.6rem;
    margin: 0.5px;
    margin-bottom:10px;
    position: relative;
    text-align: center;
}

.icon-button {
    margin-bottom: 10px;
}

.github i {
    transition: all 0.2s ease-in-out;
}

.github i:hover {
    color : #FFFFFF;
    box-shadow: 0 5px 15px #333;
}

.linkedin{
    transition: all 0.2s ease-in-out;
}

.linkedin:hover {
    color : #FFFFFF;
    box-shadow: 0 5px 15px #333;
}

@media (max-width: 768px) {
    .social-media-div {
        text-align: center;
    }
}